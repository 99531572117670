//= require window.requestanimationframe/requestanimationframe.js
//= require slick-carousel/slick/slick.js

$(function() {
  'use strict';

  function init () {
    var $tablists = $('.js-tablist'),
        $document = $(document);

    $tablists.each(function(index, tablist) {
      var $tablist = $(tablist),
          $items = $tablist.find('.js-tablist-item'),
          $buttons = $tablist.find('[role="tab"]'),
          $panels,
          panels = [],
          slider = false,
          isSticky = false;

      if($tablist.hasClass('js-tablist-carousel')) {
        slider = true;

        $tablist.slick({
          infinite: false,
          arrows: false,
          variableWidth: true,
          dots: false,
          centerMode: true,
          swipe: false
        });

        updatePositions($items, $($items[0]));

        $tablist.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
          updatePositions($items, $($items[nextSlide]));
        });
      }

      if($tablist.hasClass('js-tablist-sticky')) {
        isSticky = true;
      }

      $buttons.each(function(index, button) {
        var $button = $(button),
            $panel = $('#' + $button.attr('aria-controls'));

        $button.attr('aria-selected', false);
        $panel.attr('aria-hidden', true);

        if(index == 0) {
          $button.attr('aria-selected', true);
          $panel.attr('aria-hidden', false);
        }
        panels.push($panel[0]);
      });

      $panels = $(panels);

      $buttons.on('click', function(event) {
        event.preventDefault();

        var $button = $(event.currentTarget),
            $panel = $('#' + $button.attr('aria-controls'));

        $buttons.attr('aria-selected', false);
        $panels.attr('aria-hidden', true);

        $button.attr('aria-selected', true);
        $panel.attr('aria-hidden', false);

        if(slider) {
          $tablist.slick('slickGoTo', $buttons.index($button))
        }

        if(isSticky) {
          $('html, body').animate({
            scrollTop: 0
          }, 300);
        }
      });
    });
  }

  function updatePositions ($items, $nextItem) {
    var nextPosition = $items.index($nextItem);

    $items.each(function(index, item) {
      var $item = $(item);

      $item.attr('data-position', index-nextPosition);
    });
  }

  function reinit () {
    $('.js-tablist-button').off("click");

    init();
  }

  $(document).on('smoothState:update', function(event) {
    reinit();
  });

  $(document).on('tablist:update', function(event) {
    reinit();
  });

  init();

});
