$(function() {
  'use strict';

  function init () {
    var $search       = $('#search-form'),
        $container    = $('#'+$search.data('container')),
        $input        = $search.find('#s'),
        $document     = $(document),
        timer         = 1000,
        ajax          = false,
        lastType      = false,
        currentSearch = "",
        lastSearch    = "";

    $search.on("keyup", function(event) {
      currentSearch = $input.val();

      if(currentSearch) {
        lastType = Math.floor(Date.now());
      } else {
        $container.html('');
        lastSearch = "";
      }
    });

    $search.on("submit", function(event) {
      event.preventDefault();

      var url = $search.attr('action');

      if($search.serialize() != "") {
        url += '?'+$search.serialize();
      }

      if(!ajax) {
        $.ajax({
          url: url + (url.indexOf('?') == -1 ? '?' : '&') + 'ajax=1',
          method: $search.attr('method'),
          beforeSend: function(jqXHR, settings) {
            ajax = true;
            $container.addClass('is-loading');
          },
          success: function(data, textStatus, jqXHR) {
            $container.html($(data).find('#'+$search.data('container')).html());
            $document.trigger('tablist:update');
          },
          error: function(jqXHR, textStatus, errorThrown) {
            alert("Une erreur est survenue :(");
          },
          complete: function(jqXHR, textStatus) {
            ajax = false;
            lastSearch = currentSearch;
            $container.removeClass('is-loading');
          }
        });
      }

    });

    $document.on("search:focus", function(event) {
      $input.focus();
    });

    (function loop() {
      requestAnimationFrame(loop);

      if(lastType && $input.val()) {
        var currentTime = Math.floor(Date.now()),
            delta = currentTime - lastType;

        if(delta >= timer && currentSearch != lastSearch) {
          $search.trigger('submit');

          lastType = false;
        }
      }

    })();
  }

  function reinit () {
    init();
  }

  $(document).on('smoothState:update', function(event) {
    reinit();
  });

  init();
});
