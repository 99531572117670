//= require intersection-observer/intersection-observer.js

$(function() {
  'use strict';

  function init () {
    var $summaries = $('.js-summary');

    $summaries.each(function(index, summary) {
      var $summary = $(summary),
          $links = $summary.find('.js-summary-link'),
          observer,
          threshold = [],
          $window = $(window),
          $wrapper = $('.js-wrapper-container'),
          didScroll = true;

      if($summary.length) {
        $wrapper.addClass('has-summary');
      }

      for(var i = 1; i <= 100; i++) {
        threshold.push(i/100);
      }

      observer = new IntersectionObserver(function (observables) {
        var $observables = $(observables);

        $observables.each(function (index, observable) {

          var $observable = $(observable.target);

          if(observable.boundingClientRect.top < $window.height()/2 && observable.boundingClientRect.bottom > $window.height()/2) {
            $links.removeClass('is-current');
            $links.filter('[href="#' + $observable.attr('id') + '"]').addClass('is-current');
          }
        })
      }, {
        threshold: threshold
      });

      $links.each(function(index, link) {
        var $link = $(link),
            section = document.querySelector($link.attr('href'));

        observer.observe(section);
      });
    });
  }

  function reinit () {
    init();
  }

  $(document).on('smoothState:update', function(event) {
    reinit();
  });

  init();
});