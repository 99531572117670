//= require window.requestanimationframe/requestanimationframe.js

$(function() {
  'use strict';


  function init () {
    var $header = $('#header'),
        $main = $('#main'),
        $window = $(window),
        headerHeight = $header.outerHeight(),
        didScroll = $header.hasClass('not-sticky') ? false : true;


    if(didScroll) {
      $main.css({
        paddingTop: headerHeight + 'px'
      });
    }

    $window.on('scroll', function () {
      didScroll = $header.hasClass('not-sticky') ? false : true;
    });

    (function loop () {
      requestAnimationFrame(loop);

      if (didScroll) {
        if($window.scrollTop() > headerHeight) {
          $header.addClass('is-sticky');
          $body.addClass('header-sticky');
        } else {
          $header.removeClass('is-sticky');
          $body.removeClass('header-sticky');
        }

        didScroll = false;
      }
    })();
  }


  function reinit () {
    init();
  }

  $(document).on('smoothState:update', function(event) {
    reinit();
  });

  init();

});
