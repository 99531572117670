$(function() {
  'use strict';

  var $document = $(document);

  $document.keyup(function(event) {
    console.log('KEYDOWN');
    switch(event.which) {
      case 37: // left
        var $target = $('.js-keyboard-left');
        console.log($target.length);
        if($target.length > 0) {
          window.location = $target.first().attr('href');
        }
      break;

      case 39: // right
        var $target = $('.js-keyboard-right');
        console.log($target.length);
        if($target.length > 0) {
          window.location = $target.first().attr('href');
        }
      break;

      default: return;
    }
  });

});
