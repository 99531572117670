$(function() {
  'use strict';

  function init () {
    $('.js-print-button').on('click', function () {
      if(window.print) {
        window.print();
      } else {
        document.execCommand('print', false, null);
      }
    });
  }

  function reinit () {
    $('.js-print-button').off("click");

    init();
  }

  $(document).on('smoothState:update', function(event) {
    reinit();
  });

  init();

});
