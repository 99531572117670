//= require platform/platform.js

$(function() {
  'use strict';

  var $privates = $('.js-private');

  // console.log('Browser : ', platform.name);
  // console.log('OS : ', platform.os.family);

});