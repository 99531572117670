$(function() {
  'use strict';

  function init () {
    var $collapsibles = $('.js-collapsible');

    $collapsibles.each(function(index, collapsible) {
      var $collapsible = $(collapsible),
          id = $collapsible.data('id'),
          expanded = $collapsible.data('expanded'),
          collapsed = $collapsible.data('collapsed'),
          $buttons = $collapsible.find('[aria-controls="collapsible-' + id + '"]'),
          $content = $collapsible.find('#collapsible-' + id),
          $icons = $collapsible.find('.js-collapsible-icon'),
          height = 'none';

      $buttons.attr('aria-expanded', false);
      $content.attr('aria-hidden', true).css({maxHeight: 0});
      $icons.text(collapsed);

      $buttons.on("click", function(event) {
        event.preventDefault();

        var state = $content.attr('aria-hidden') === "true" ? true : false;

        $buttons.attr('aria-expanded', state);
        $content.attr('aria-hidden', !state).css({ maxHeight: (state ? height : 0) } );

        $icons.text(state ? expanded : collapsed);
      });
    });
  }

  function reinit () {

    $('.js-collapsible').each(function(index, collapsible) {
      var $collapsible = $(collapsible),
          id = $collapsible.data('id'),
          $buttons = $collapsible.find('[aria-controls="collapsible-' + id + '"]');

      $buttons.off("click");
    });

    init();
  }

  $(document).on('smoothState:update', function(event) {
    reinit();
  });

  init();

});
