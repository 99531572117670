$(function() {
  'use strict';

  function init () {
    var $anchors = $('.js-anchor'),
        $body = $('html, body'),
        $header = $('#header');

    $anchors.on('click', function(event) {
      event.preventDefault();

      var $link  = $(event.currentTarget),
          $target = $($link.attr('href'));

      $body.animate({
        scrollTop: $target.offset().top - $header.outerHeight()
      }, 300);
    });
  }

  function reinit () {
    $('.js-anchor').off("click");

    init();
  }

  $(document).on('smoothState:update', function(event) {
    reinit();
  });

  init();
});