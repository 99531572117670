$(function() {
  'use strict';

  function init () {
    var $navigation = $('#navigation'),
        $buttons = $('.js-navigation-button'),
        $links = $navigation.find('.js-navigation-link'),
        $body = $('body'),
        $document = $(document);

    $buttons.each(function(index, button) {
      var $button = $(button),
          $overlay = $('#' + $button.attr('aria-controls'));

      $button.on("click", function(event) {
        event.preventDefault();

        var state = $overlay.attr('aria-hidden') === "true" ? true : false;

        $button.attr('aria-expanded', state);
        $overlay.attr('aria-hidden', !state);

        if(state) {
          $body.addClass('no-scroll');
        } else {
          $body.removeClass('no-scroll');
        }

        if(state && $button.attr('aria-controls') == "search") {
          $document.trigger('search:focus');
        }
      });
    });

    $document.on('click', function(event) {

      if(Modernizr.touchevents) {
        var $this = $(event.target);

        if($this.hasClass('js-navigation-link')) {
          if(!$this.parent().hasClass('is-active')) {
            event.preventDefault();

            $links.parent().removeClass('is-active');

            $this.parent().addClass('is-active');
          }
        } else {
          $links.parent().removeClass('is-active');
        }
      }
    });
  }

  function reinit () {
    $('.js-navigation-button').each(function(index, button) {
      $(button).off("click");
    });
    $document.off("click");
    $body.removeClass('no-scroll');

    init();
  }

  $(document).on('smoothState:update', function(event) {
    reinit();
  });

  init();


});
