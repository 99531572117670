$(function() {

  function init () {

    $("#pagination").empty();

    $.ajax({
      url:"/wp/wp-admin/admin-ajax.php",
      type:'POST',
      data:'action=get_pagination&current_step_id=' + getCookie('step') + '&current_page_url=' + window.location.href,

      success:function(results) {
        $("#pagination").replaceWith(results);
      }
    });

  }

  function getCookie (cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
  }

  $(document).on('smoothState:update', function(event) {
    init();
  });

  init();
});
